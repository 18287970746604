@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;500;600;700;800&Open+Sans:wght@400;700&family=Roboto+Mono&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

a:not(.MuiButton-root):link,
a:not(.MuiButton-root):active,
a:not(.MuiButton-root):visited {
  color: #780000;
}

*::webkit-scrollbar {
  display: none;
}
