@import url(https://fonts.googleapis.com/css2?family=Muli:wght@400;500;600;700;800&Open+Sans:wght@400;700&family=Roboto+Mono&display=swap);
.div {
  display: flex;
  justify-content: center;
}

.container {
  margin: 24px 0 16px;
}

.character {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  font-size: 32px;
  border-radius: 5px;
}

.character--error {
  border-color: #d32f2f;
}

.character--selected {
  color: black;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

a:not(.MuiButton-root):link,
a:not(.MuiButton-root):active,
a:not(.MuiButton-root):visited {
  color: #780000;
}

*::webkit-scrollbar {
  display: none;
}

